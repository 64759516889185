import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import About from '../../../assets/svg/about.svg';
import Blog from '../../../assets/svg/blog.svg';
import Contact from '../../../assets/svg/contact.svg';
import Home from '../../../assets/svg/home.svg';
import Services from '../../../assets/svg/services.svg';
import { Link } from '../link';
import cx from 'classnames';
import { getCurrentLanguageData } from '../../../utils/get-current-language-data';
import { GroupQuery } from '../../../types';

const query = graphql`
  query {
    allPrismicHeader {
      group(field: lang) {
        fieldValue
        nodes {
          data {
            about_link_text
            blog_link_text
            contact_link_text
            services_link_text
            home_page_link_text
          }
        }
      }
    }
  }
`;

interface MenuData {
  about_link_text: string;
  blog_link_text: string;
  contact_link_text: string;
  services_link_text: string;
  home_page_link_text: string;
}

interface MenuQuery {
  allPrismicHeader: GroupQuery<MenuData>;
}

export interface MenuProps {
  className?: string;
  onMenuClose?: () => void;
}

export const Menu: React.FC<MenuProps> = ({
  className,
  onMenuClose = () => {},
}) => {
  const data = useStaticQuery<MenuQuery>(query);
  const menuData = getCurrentLanguageData(data.allPrismicHeader.group);

  const menuItems = [
    {
      key: 'start',
      label: menuData.home_page_link_text,
      icon: Home,
      route: '/',
    },
    {
      key: 'about',
      label: menuData.about_link_text,
      icon: About,
      route: '/#about_us',
    },
    {
      key: 'services',
      label: menuData.services_link_text,
      icon: Services,
      route: '/#services',
    },
    {
      key: 'blog',
      label: menuData.blog_link_text,
      icon: Blog,
      route: '/blog',
    },
    {
      key: 'contact',
      label: menuData.contact_link_text,
      icon: Contact,
      route: '/contact',
    },
  ];

  return (
    <div
      className={cx(
        className,
        'grid grid-flow-row grid-cols-2 gap-y-14 justify-items-center w-56'
      )}
    >
      {menuItems.map(menuItem => (
        <Link
          key={menuItem.key}
          to={menuItem.route}
          className="group header-xs text-greyscale-mediumlight hover:text-ecit-green text-center flex flex-col items-center"
          onClick={onMenuClose}
        >
          <menuItem.icon className="stroke-current" />
          <span className="block pt-3 group-hover:font-bold">
            {menuItem.label}
          </span>
        </Link>
      ))}
    </div>
  );
};
