import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Hamburger from '../../../assets/svg/hamburger.svg';
import Close from '../../../assets/svg/close.svg';
import CustomerPanel from '../../../assets/svg/customer-panel.svg';
import { Button, LinkButton } from '../button/button';
import { Menu } from '../menu/menu';
import { LanguageSelect } from '../language-select/language-select';
import { Link } from '../link';
import { Image, GroupQuery } from '../../../types';
import { getCurrentLanguageData } from '../../../utils/get-current-language-data';

const query = graphql`
  query {
    allPrismicHeader {
      group(field: lang) {
        fieldValue
        nodes {
          data {
            customer_panel_button_text
            ecit_law_logo {
              alt
              url
            }
            your_customer_panel_link_text
          }
        }
      }
    }
  }
`;

export interface HeaderProps {
  username?: string;
  menuHidden?: boolean;
}

interface HeaderData {
  customer_panel_button_text: string;
  ecit_law_logo: Image;
  your_customer_panel_link_text: string;
}

interface HeaderQuery {
  allPrismicHeader: GroupQuery<HeaderData>;
}

export const Header: React.FC<HeaderProps> = ({ username, menuHidden }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const data = useStaticQuery<HeaderQuery>(query);
  const headerData = getCurrentLanguageData(data.allPrismicHeader.group);

  return (
    <>
      <div className="fixed left-0 right-0 top-0 z-30 bg-white">
        <div className="m-auto max-w-body px-4">
          <div className="h-24 flex justify-between items-center">
            <div className="hidden lg:block ml-16 pl-2.5">
              <Link to="/">
                {headerData.ecit_law_logo && (
                  <img
                    className="w-48"
                    src={headerData.ecit_law_logo.url}
                    alt={headerData.ecit_law_logo.alt}
                  />
                )}
              </Link>
            </div>
            {!menuHidden && !menuOpen ? (
              <Hamburger
                className="lg:hidden"
                onClick={() => setMenuOpen(true)}
              />
            ) : !menuHidden && menuOpen ? (
              <Close className="lg:hidden" onClick={() => setMenuOpen(false)} />
            ) : (
              <div></div>
            )}
            <div className="block lg:hidden">
              {headerData.ecit_law_logo && (
                <img
                  src={headerData.ecit_law_logo.url}
                  alt={headerData.ecit_law_logo.alt}
                />
              )}
            </div>
            {username ? (
              <div className="flex items-center mr-10">
                <span className="text-sm flex leading-4 uppercase font-bold text-ecit-blue pr-5 border-r border-greyscale-mediumlight">
                  <CustomerPanel />
                  <span className="pl-2 hidden lg:inline">{username}</span>
                </span>
                <LinkButton to="/" variant="link" className="ml-5">
                  {headerData.your_customer_panel_link_text}
                </LinkButton>
              </div>
            ) : (
              <>
                {/* <a href="https://lawmatics.eyedea.dev/home" target="_blank"> */}
                <Button
                  variant="outline"
                  width="min"
                  className="mr-10 hidden lg:inline"
                  onClick={() => {
                    alert(
                      'Customer Portal will be available soon! Stay tuned!'
                    );
                  }}
                >
                  {headerData.customer_panel_button_text}
                </Button>
                <span className="inline lg:hidden">
                  <CustomerPanel className="w-8 h-8" />
                </span>
                {/* </a> */}
              </>
            )}
          </div>
        </div>
        {!menuHidden && (
          <div className="relative m-auto max-w-body px-4">
            <div className="hidden lg:block absolute right-11 top-auto pt-9">
              <Menu />
            </div>
          </div>
        )}
      </div>
      {!menuHidden && menuOpen && (
        <div className="lg:hidden z-20 fixed top-0 h-screen bg-white w-full flex flex-col items-center justify-around pt-44">
          <Menu onMenuClose={() => setMenuOpen(false)} />
          <LanguageSelect />
        </div>
      )}
    </>
  );
};
