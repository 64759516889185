import React, {
  InputHTMLAttributes,
  KeyboardEventHandler,
  useCallback,
  useRef,
  useState,
} from 'react';
import { FakeInput } from '../input/input';
import SaveIcon from '../../../assets/svg/save.svg';
import cx from 'classnames';

export interface FileInputProps extends InputHTMLAttributes<HTMLInputElement> {
  full?: boolean;
  invalid?: boolean;
  required?: boolean;
}

export const FileInput: React.FC<FileInputProps> = ({
  className,
  full,
  invalid,
  required,
  placeholder,
  onChange,
  ...props
}) => {
  const inputElem = useRef<HTMLInputElement>();
  const placeholderElem = useRef<HTMLDivElement>();
  const [fileName, setFileName] = useState<string>();

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    e => {
      if (onChange) {
        onChange(e);
      }
      if (e.target.files[0]) {
        setFileName(e.target.files[0].name);
      }
    },
    [onChange]
  );

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = event => {
    if (placeholderElem.current !== document.activeElement) {
      return;
    }
    if (event.key === 'Enter' || event.key === ' ') {
      inputElem.current.click();
    }
  };

  return (
    <div>
      <div
        className={cx(className, 'relative', {
          'inline-block': !full,
        })}
      >
        <FakeInput
          placeholder={placeholder}
          required
          value={fileName}
          tabIndex={-1}
          className="overflow-ellipsis overflow-hidden whitespace-nowrap pr-11"
          full={full}
        />
        <input
          type="file"
          className="hidden"
          {...props}
          ref={inputElem}
          onChange={handleChange}
        />
        <div
          ref={placeholderElem}
          onClick={() => inputElem.current.click()}
          className="absolute left-0 right-0 top-0 bottom-0 z-10 cursor-pointer"
          tabIndex={0}
          onKeyDown={handleKeyDown}
        ></div>
        <SaveIcon className="absolute right-6 bottom-4" />
      </div>
    </div>
  );
};
