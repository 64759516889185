import React from 'react';
import cx from 'classnames';
import { usePageDataContext } from '../../../hooks/use-page-context';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import { LANGUAGES } from '../../../config/const';
import { GroupQuery } from '../../../types';
import { getCurrentLanguageData } from '../../../utils/get-current-language-data';

const query = graphql`
  query {
    allPrismicFooter {
      group(field: lang) {
        fieldValue
        nodes {
          data {
            select_language_text
          }
        }
      }
    }
  }
`;

interface LanguageData {
  select_language_text: string;
}

interface LanguageSelectQuery {
  allPrismicFooter: GroupQuery<LanguageData>;
}

export const LanguageSelect: React.FC<{}> = () => {
  const { locale, location, alternate_languages } = usePageDataContext();
  const data = useStaticQuery<LanguageSelectQuery>(query);
  const languageSelectData = getCurrentLanguageData(
    data.allPrismicFooter.group
  );

  const handleChangeLanguage = (locale: string, lang: string) => {
    const slug = alternate_languages?.find(
      alt_lang => alt_lang.lang === lang
    )?.uid;
    if (slug) {
      navigate(`/${locale}/${slug}`);
    } else {
      const url = location.pathname.split('/');
      url[1] = locale;
      navigate(url.join('/'));
    }
  };

  return (
    <div className="flex">
      <span className="header-xs text-greyscale-mediumlight mr-3.5">
        {languageSelectData.select_language_text}
      </span>
      {LANGUAGES.map((language, i) => (
        <div
          key={language.locale}
          onClick={() => handleChangeLanguage(language.locale, language.lang)}
          className={cx('flex cursor-pointer', {
            'mr-9': i < LANGUAGES.length - 1,
          })}
        >
          <language.icon></language.icon>
          <span
            className={cx('pl-2', {
              'header-xs text-greyscale-mediumlight':
                locale !== language.locale,
              'header-xs text-ecit-blue font-bold': locale === language.locale,
            })}
          >
            {language.label}
          </span>
        </div>
      ))}
    </div>
  );
};
