import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Input } from '../shared/input/input';
import { FileInput } from '../shared/file-input/file-input';
import { Button } from '../shared/button/button';
import { graphql, useStaticQuery } from 'gatsby';
import { getCurrentLanguageData } from '../../utils/get-current-language-data';
import { GroupQuery } from '../../types';

export const query = graphql`
  query {
    allPrismicJoinOurTeam {
      group(field: lang) {
        fieldValue
        nodes {
          data {
            form_button_label
            cv_input_placeholder
            form_description
            email_input_placeholder
            name_input_placeholder
            number_input_placeholder
            form_title
            success_message
            error_message
            required_field_label
          }
        }
      }
    }
  }
`;

interface JoinOurTeamData {
  form_button_label: string;
  cv_input_placeholder: string;
  form_description: string;
  email_input_placeholder: string;
  name_input_placeholder: string;
  number_input_placeholder: string;
  form_title: string;
  success_message: string;
  error_message: string;
  required_field_label: string;
}

interface JoinOurTeamQuery {
  allPrismicJoinOurTeam: GroupQuery<JoinOurTeamData>;
}

export const JoinOurTeam = () => {
  const data = useStaticQuery<JoinOurTeamQuery>(query);
  const joinOurTeamData = getCurrentLanguageData(
    data.allPrismicJoinOurTeam.group
  );

  const [cvFile, setCvFile] = useState<FileList>();
  const { register, handleSubmit, control } = useForm();
  const onSubmit = async data => {
    const { cv, ...formData } = data;
    const attachment = new FormData();

    if (cvFile) attachment.append('attachment', cvFile[0]);

    try {
      const response = await axios.post(
        `${process.env.GATSBY_API_URL}/joinTeamForm`,
        formData
      );
      if (response.data?.id) {
        await axios.post(
          `${process.env.GATSBY_API_URL}/attachment/${response.data.id}`,
          attachment
        );
        toast.success(joinOurTeamData.success_message);
      }
    } catch (err) {
      const message = err.resoponse?.data?.message
        ? err.resoponse?.data?.message
        : joinOurTeamData.error_message;
      toast.error(message);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2 className="label-m text-ecit-blue mt-28 pt-2 sm:mt-0 sm:pt-0">
        {joinOurTeamData.form_title}
      </h2>
      <p className="paragraph font-bold text-ecit-green mt-4 mb-16">
        {joinOurTeamData.form_description}
      </p>
      <Input
        required
        full
        className="mb-9"
        placeholder={joinOurTeamData.email_input_placeholder}
        {...register('email', { required: true })}
      />
      <Input
        required
        full
        className="mb-9"
        placeholder={joinOurTeamData.name_input_placeholder}
        {...register('name', { required: true })}
      />
      <Input
        required
        full
        className="mb-9"
        placeholder={joinOurTeamData.number_input_placeholder}
        {...register('phoneNumber', { required: true })}
      />
      <Controller
        name="cv"
        control={control}
        rules={{ required: true }}
        render={({ field: { name, value, onChange } }) => (
          <FileInput
            required
            full
            className="mb-6"
            placeholder={joinOurTeamData.cv_input_placeholder}
            value={value}
            name={name}
            onChange={e => {
              onChange(e);
              e.target.files && setCvFile(e.target.files);
            }}
          />
        )}
      />
      <p className="header-xs text-greyscale-mediumlight font-normal mb-8">
        {`* ${joinOurTeamData.required_field_label}`}
      </p>
      <Button type="submit" variant="outline">
        {joinOurTeamData.form_button_label}
      </Button>
    </form>
  );
};
