import React from 'react';
import cx from 'classnames';

type PaddingRightType = 'default' | 'big';

export interface ContainerProps {
  paddingRight?: PaddingRightType;
}

export const Container: React.FC<ContainerProps> = ({
  paddingRight = 'default',
  children,
}) => {
  return (
    <div
      className={cx('max-w-body m-auto px-4 sm:pl-33 lg:pl-42', {
        'lg:pr-69': paddingRight === 'default',
        'lg:pr-84': paddingRight === 'big',
      })}
    >
      {children}
    </div>
  );
};
